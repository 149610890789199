<template>
  <div class="keepRecord">
     
         <van-tabs v-model="active" animated sticky type="card" title-active-color="#F6D0AB" title-inactive-color="#39394D">
            <van-tab title="卖料定价" name="Price">
                <keep-alive>
                    <sellingPrice :activeName='activeName'/>
                </keep-alive>
    
            </van-tab>
            <van-tab title="卖料结算" name="Settlement">
                <keep-alive>
                    <salesSettlement />
                </keep-alive>
            </van-tab>
         </van-tabs>
    <!-- 右侧结算 -->
    <div class="tabsRight" @click="tobreakcontract">
      <span>结算</span>
      <van-icon name="arrow" slot="right" />
    </div>
         <!-- 左侧图标 -->
         <div class="tabsLeftIcon" @click="back">
            <van-icon name="arrow-left"  color="#F6D0AB"/>
        </div>


  </div>
</template>

<script>
import salesSettlement from './salesSettlement/index.vue'
import sellingPrice from './sellingPrice/index.vue'
export default {
            components:{
                    salesSettlement,
                    sellingPrice
            },
            data() {
                return {
                    active:'Price',
                    activeName:'ALL',
                    // 全部 待审核  待送货 已完成  已拒绝
                    name:['ALL','SUSPENSE','UNDELIVERED','DONE','FAIL']
                }
            },
            mounted() {
               this.$Loading.show({text:'加载中...'})

                this.active = this.$route.query.active?this.$route.query.active:'Price'
            },
            methods:{
                back(){
                    this.$router.push({
                        name:'person'
                    })
                },
                tobreakcontract(){
                  this.$router.push({
                    name:'breakContract',
                    query:{
                      active:'sell',
                      path: "sellSettlement"
                    }
                  })
                }
            },
}
</script>

<style lang="scss" scoped>
::v-deep{
   
    .van-tabs__wrap{
        height: 88px;
        padding: 0 220px;
        background-color: #39394D;
    }
    .van-tabs__line{
        display: none;
    }
    .van-tabs__nav--line {
        padding-bottom: 0 !important;
}
    .van-tabs__nav{
        height: 50px;
        margin-top: 16px;
        border-radius: 10px ;
    }
    .van-tab{
        height: 100%;
        line-height: 50px;
        font-size: 28px;
        padding: 0;
    }
  .van-tab--active {
    background:  #39394D !important;
    border-radius: 20px;
  }
  .van-tabs__nav--card .van-tab {
    border-right: none;
  }
  .van-tabs__nav--card {
    border: none;
    border-radius: 100px;
    padding: 2px;
  }
}
.keepRecord{
    background-color: #f5f5f5;
}
.tabsLeftIcon{
    position: fixed;
    top: 30px;
    left: 10px;
    z-index: 9999;
    .van-icon{
        font-size: 32px;
    }
}
.tabsRight {
  position: fixed;
  z-index: 9999;
  top: 18px;
  right: 30px;
  height: 50px;
  font-size: 28px;
  line-height: 50px;
  color: #f6d0ab;
  i {
    top: 4px;
  }
}
</style>